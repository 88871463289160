import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
//
import App from './App';

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <Suspense>
          <App />
        </Suspense>
      </QueryParamProvider>
    </BrowserRouter>
  </HelmetProvider>
);

// Register notification for push notifications
serviceWorkerRegistration.register();
